import {useAuthStore} from "@/store/auth.store.js";

//import {useMastersStore} from "@/store/masters.store";

async function storeAccessToken(to, from, next) {
  if (to.query.error) {
    console.log("Error - returning to Login Page");
    // TODO: Handle showing an error popup or notification
    next({name: 'auth-login'});
  } else if (to.query.token && to.query.token !== "Connected") {
    const authStore = useAuthStore();
    authStore.setAccessToken(to.query.token);
    authStore.setLoggedIn(true);
    authStore.setAuthenticated(true);
    //console.log("Token stored, redirecting to dashboard or stored path");

    // Check if a redirect path is stored, if not redirect to dashboard
    //console.log("redirectPath", authStore.redirectPath);
    if (authStore.redirectPath) {

      const redirectPath = authStore.redirectPath;
      authStore.clearRedirectPath(); // Clear the stored path to prevent future automatic redirections
      next({path: redirectPath});
    } else {
      next({name: 'dashboard'});
    }
  } else {
    next(); // Continue with the current navigation
  }
}

export default [
  {
    path: '/auth/',
    component: () => import('@/layouts/login/AuthLayout.vue'),
    children: [
      {

        path: 'login',
        name: 'auth-login',
        component: () => import("@/components/login/LoginComponent.vue"),
        meta: {requiresAuth: false}
      },
      {
        // We get to this post login
        path: 'success',
        name: 'login-success',
        component: () => import("@/components/login/LoginComponent.vue"),
        meta: {requiresAuth: false},
        beforeEnter: storeAccessToken,
      },
      {
        // We get to this post login
        path: 'register',
        name: 'register',
        component: () => import("@/components/login/RegisterComponent.vue"),
        meta: {requiresAuth: false}
      },
    ], // end children
  },
];
