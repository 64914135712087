// stores/auth.store.js
import {defineStore} from 'pinia';
import {ref, watch} from 'vue';
import router from '@/router';
import {useProfileStore} from '@/store/profile.store'; // Import the profile store

export const useAuthStore = defineStore('auth', () => {
  const isAuthenticated = ref(false);
  const isLoggedIn = ref(false);
  const accessToken = ref(null);
  const redirectPath = ref(null);
  const isLoading = ref(false);

  const profileStore = useProfileStore(); // Access the profile store

  function setLoggedIn(payload) {
    isLoggedIn.value = payload;
  }

  function setAccessToken(payload) {
    accessToken.value = payload;
  }

  function setAuthenticated(payload) {
    isAuthenticated.value = payload;
  }

  function setRedirectPath(path) {
    redirectPath.value = path;
  }

  function clearRedirectPath() {
    redirectPath.value = null;
  }

  function showLoading() {
    isLoading.value = true;
  }

  function hideLoading() {
    isLoading.value = false;
  }

  async function logout() {
    setAuthenticated(false);
    setLoggedIn(false);
    setAccessToken(null);
    await router.push({name: 'auth-login'});
    // Clear session storage
    sessionStorage.clear();
  }

  // Watch for changes to the access token
  watch(accessToken, async (newToken) => {
    if (newToken && isAuthenticated.value) {
      await profileStore.fetchProfileData();
    }
  });

  return {
    isAuthenticated,
    isLoggedIn,
    accessToken,
    redirectPath,
    isLoading,
    setLoggedIn,
    setAccessToken,
    setAuthenticated,
    setRedirectPath,
    clearRedirectPath,
    showLoading,
    hideLoading,
    logout
  };
}, {
  persist: {
    enabled: false,
    strategies: [
      {storage: sessionStorage, paths: ['isAuthenticated', 'accessToken']}
    ],
  },
});
